var is_worker = !self.document;
var mime_image = 'image/png';
// Window load promise
var window_load = new Promise(function (resolve) {
    window.addEventListener('load', resolve);
});
// Constants
var ns = 'http://www.w3.org/1999/xhtml';
var mime_text_regex = /^\s*(?:text\/plain)\s*(?:$|;)/i;
var size = 256; // Anything larger will causes problems in Google Chrome
var pixelgrid = 16;
var self_uri = document.currentScript.getAttribute('src');
var service_worker_container = navigator.serviceWorker;
// Elements
var canvas = document.createElementNS(ns, 'canvas');
var link = document.createElementNS(ns, 'link');
var context = canvas.getContext('2d');
// Function
export default function set_emoji_favicon(emoji, cacheWithServiceWorker) {
    // Normalize arguments
    var char = String(emoji) || '';
    var cache = Boolean(cacheWithServiceWorker);
    // Calculate sizing
    var metric = context.measureText(char);
    var iconsize = metric.width;
    var center = (size + size / pixelgrid) / 2;
    var scale = Math.min(size / iconsize, 1);
    var center_scaled = center / scale;
    // Draw emoji
    context.clearRect(0, 0, size, size);
    context.save();
    context.scale(scale, scale);
    context.fillText(char, center_scaled, center_scaled);
    context.restore();
    // Update favicon element
    link.href = canvas.toDataURL(mime_image);
    document.getElementsByTagName('head')[0].appendChild(link);
    // Add favicon to cache
    if (cache && service_worker_container) {
        canvas.toBlob(function (blob) {
            var reader = new FileReader();
            reader.addEventListener('loadend', function () {
                var array_buffer = reader.result;
                // https://developers.google.com/web/fundamentals/primers/service-workers/registration
                window_load.then(function () {
                    service_worker_container.register(self_uri, { scope: '/' });
                    service_worker_container.ready.then(function (registration) {
                        // https://developers.google.com/web/updates/2011/12/Transferable-Objects-Lightning-Fast
                        registration.active.postMessage(array_buffer, [array_buffer]);
                    });
                });
            });
            reader.readAsArrayBuffer(blob);
        }, mime_image);
    }
}
// Canvas setup
canvas.width = canvas.height = size;
context.font = "normal normal normal " + size + "px/" + size + "px apple color emoji, segoe ui emoji, notocoloremoji, segoe ui symbol, android emoji, emojisymbols, emojione mozilla, sans-serif";
context.textAlign = 'center';
context.textBaseline = 'middle';
// Link setup
link.rel = 'icon';
link.type = mime_image;
link.setAttribute('sizes', size + "x" + size);
// Scan document for statically-defined favicons
var lastlink = [].slice.call(document.getElementsByTagNameNS(ns, 'link'), 0).filter(function (link) {
    return link.rel.toLowerCase() === 'icon' && mime_text_regex.test(link.type);
}).pop();
if (lastlink) {
    var xhr_1 = new XMLHttpRequest;
    var uri = lastlink.href.trim().replace(/^data:(;base64)?,/, "data:text/plain;charset=utf-8$1,");
    xhr_1.open('GET', uri);
    xhr_1.addEventListener('load', function () {
        if (xhr_1.readyState === xhr_1.DONE && xhr_1.status === 200) {
            var emoji = xhr_1.responseText;
            set_emoji_favicon(emoji, false);
        }
    });
    xhr_1.send();
}
